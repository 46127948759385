import React from 'react'
import Header from '../components/layout/Header'
import MainWrapper from '../components/layout/Main'
import Footer from '../components/layout/Footer'


import MoodBad from '@material-ui/icons/MoodBad'


const style = {
    icon:{
        position: 'relative',
        top: '6px',
        marginRight: '15px'
    }
}

export default function Contact() {
    return (
        <div className='outter_wrapper'>
            <Header pageTitle='Page not Found' />
            <MainWrapper> 
                <h1> Oh no! Page not found </h1>  
                <h3><MoodBad style={style.icon} />This could be a bug or maybe this page does not exist!</h3>
                
            </MainWrapper>
            <Footer />

        </div>
    )
}

