import React, { Fragment } from 'react'
import { StaticQuery, Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Grid from "@material-ui/core/Grid"
import Code from '@material-ui/icons/Code'
import LocalCafe from '@material-ui/icons/LocalCafe'
import MyLocation from '@material-ui/icons/MyLocation'

import styled from 'styled-components'

import jean from '../../images/jean_face.jpg'
import '../../styles/main.scss'


const AppBarRoot = styled(AppBar)`
    background-color: transparent;
    box-shadow: none;
`

const AvatarImg = styled.div`
        border: 2px solid #fff;
        box-shadow: 0 0 0 2px rgba(0,0,0,.1);
        height: 36px;
        width: 36px;
        border-radius: 50%;
        overflow: hidden;
        float: left;
`

const AvatarLink = styled(Button)`
    min-width: auto;
    padding: 0;
`

const TextTransform = styled(Button)`
    text-transform: none;
    font-family: 'Zilla Slab';
    opacity: .7;
`

function MenuBar( { pageTitle } ) {

    return (
        <StaticQuery
            query={graphql`
            query {
                site{
                    siteMetadata {
                        title
                        skill
                        author
                        description
                        siteUrl
                        twitter
                    }
                }
            }
        `}
            render={({
                site: {
                    siteMetadata: { siteUrl, title, twitter, description, skill },
                },
                
                
            }) => (
                    <Fragment>
                        
                        <Helmet defaultTitle={`${ title } - ${ skill }` } titleTemplate={`%s | ${title}`}>
                            <html lang="en" />
                            <title>{ pageTitle }</title>
                            <meta name="docsearch:version" content="2.0" />
                            <meta
                                name="viewport"
                                content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                            />
                            <meta name="description" content={description}></meta>
                            <meta property="og:url" content={siteUrl} />
                            <meta property="og:type" content="website" />
                            <meta property="og:locale" content="en" />
                            <meta property="og:site_name" content={title} />
                            <meta property="og:image" content={`${siteUrl}${jean}`} />
                            <meta property="og:image:width" content="512" />
                            <meta property="og:image:height" content="512" />
                            <meta name="twitter:card" content="summary" />
                            <meta name="twitter:site" content={twitter} />
                        </Helmet>

                        <AppBarRoot position="static">
                            <Toolbar>
                                <Grid
                                    justify="space-between"
                                    container
                                    spacing={24}>

                                    <Grid item>
                                        <AvatarImg>
                                            <AvatarLink component={Link} to="/">
                                                <img src={jean} alt={ `${title} avatar` } />
                                            </AvatarLink>
                                        </AvatarImg>

                                        <TextTransform component={Link} to="/">
                                            {title}
                                        </TextTransform>
                                    </Grid>

                                    <Grid item className='menu_items' >
                                        <TextTransform component={Link} to='/'> <MyLocation color='primary' style={{ marginRight: '.5rem' }} /> Home </TextTransform>
                                        <TextTransform component={Link} to='/techs'> <Code color='primary' style={{ marginRight: '.5rem' }} /> Techs </TextTransform>
                                        {/* <TextTransform component={Link} to='/portfolio/'> Portifolio </TextTransform> */}
                                        <TextTransform component={Link} to='/contact'> <LocalCafe color='primary' style={{ marginRight: '.5rem' }} /> Contact </TextTransform>
                                    </Grid>

                                </Grid>


                            </Toolbar>

                        </AppBarRoot>

                    </Fragment>


                )}
        />
    )
}



export default MenuBar