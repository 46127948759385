import React, { Component } from "react"
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

class Footer extends Component {
    render(){
        return (
            <footer>
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                >
                    <Grid item >
                        Built with <Link href='https://www.gatsbyjs.org/' target='_blank' rel="noopener noreferrer">Gatsby</Link>, <Link href='https://material-ui.com/' target='_blank' rel="noopener noreferrer">Material UI</Link> and <Link href='https://reactjs.org/' target='_blank' rel="noopener noreferrer">React.</Link> Hosted on <Link href='https://www.netlify.com/' target='_blank' rel="noopener noreferrer">Netlify.</Link> 
                    </Grid>
                </Grid>
            </footer>
        )
    }
}

export default Footer