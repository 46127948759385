import React, { Component } from "react"
import Grid from '@material-ui/core/Grid'

class Main extends Component {

    render(){

        const { helperClasses } = this.props
        const innerHalf = helperClasses ? helperClasses : ''

        return (
            <div className={`inner_wrapper ${innerHalf}`}>
                <Grid
                    container
                    alignItems='center'
                    justify='center'
                >
                    <Grid item >{this.props.children}</Grid>
                </Grid>
            </div>
        )
    }
}

export default Main